@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&family=Prompt:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Prompt:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&family=Prompt:wght@800&display=swap');

body {
  background-image: url('./assets/bg.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
}
input {
  color: #fff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
option {
  background-color: #1B1B1B;
}
select {
  appearance: none;
  background-image: url('./assets/dropdown.svg');
  background-repeat: no-repeat;
  background-position: 90%;
}
@media screen and (max-width: 600px) {
  body {
    background-image: url('./assets/bgPhoneview.svg');
    background-size: cover;
  }
  ::placeholder {
    font-size: 15px;
  }
}
#login:hover,li:hover {
  transition: 0.3s;
  color: #844CFE;
}
button:disabled {
  background-color: #8f8f8f;
  color: #1B1B1B;
}
td{
  width: 6rem;
  flex: auto;
  overflow: auto;
}
.email {
  width: 9.8rem;
  flex: auto;
}
.phone {
  width: 9.7rem;
  flex: auto;
}
label {
  width: 5rem;
  color: #848484;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
::-webkit-scrollbar {
  background: transparent;
}
